import React from "react"
import _ from "lodash";
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import InnerPadding from "../components/InnerPadding"

const CategoriesTitle = styled.h1`
    font-size: 3rem;
    display: inline-block;
    span {
        z-index: 101;
        position: relative;
    }
    &::after {
        content: "";
        height: 1.2rem;
        background-color: #f0f0ff;
        display: block;
        position: relative;
        z-index: 100;
        top: -1.2rem;
    }    
`

const CategoriesIndex = () => {
  // const siteTitle = data.site.siteMetadata.title
  // const categories = data.allMarkdownRemark.distinct
  return (
    <>
    <StaticQuery 
      query={indexQuery}
      render={ data => (
        <Layout title={data.site.siteMetadata.title}>
          <Seo title="Categories"/>
          <InnerPadding>
            <CategoriesTitle>
              <span>Categories</span>
            </CategoriesTitle>
            <hr className="contour"/>
            <div className="tag-archive-container">
              {data.allMarkdownRemark.distinct.map( category => {
                return(
                    <Link
                    className="tag-archive-link"
                    key={category}
                    to={`/categories/${_.kebabCase(category)}`}
                    >
                    <div className="tag-archive-item">{category}</div>
                    </Link>
                  )
                })}
              </div>
              <hr className="contour" style={{marginBottom:`1em`}}/>
          </InnerPadding>
        </Layout>
      )}
    />
    </>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      distinct(field: frontmatter___categories)
    }
  }
`

// export default props => (
//   <StaticQuery
//     query={indexQuery}
//     render={data => (
//       <CategoriesIndex props data={data} />
//     )}
//   />
// )
export default CategoriesIndex;